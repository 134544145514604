import {
  SET_GLOBAL_STATS_DATA,
  SET_MY_STATS_DATA,
  SET_STATS_INDEX,
} from "./constants"
import getFirebase from "../../utils/firebase"
import { getDatabase, ref, set, increment, child, get } from "firebase/database"

const setGlobalStatsData = data => ({
  type: SET_GLOBAL_STATS_DATA,
  payload: {
    ...data,
  },
})

export const setMyStatsData = data => ({
  type: SET_MY_STATS_DATA,
  payload: {
    ...data,
  },
})

export const setStatsIndex = index => ({
  type: SET_STATS_INDEX,
  payload: {
    index,
  },
})

export const incrementMyData = type => async (dispatch, getState) => {
  const { heads, tails } = getState().stats.my
  let newHeads = heads
  let newTails = tails
  if (type === "heads") newHeads = newHeads + 1
  if (type === "tails") newTails = newTails + 1
  dispatch(setMyStatsData({ heads: newHeads, tails: newTails }))
}

export const incrementData = type => async (dispatch, getState) => {
  try {
    getFirebase()
    const db = getDatabase()
    const dataRef = ref(db, "data")
    const typeRef = child(dataRef, type)
    await set(typeRef, increment(1))
    const snap = await get(dataRef)
    if (snap.exists()) {
      const data = snap.val()
      dispatch(setGlobalStatsData(data))
    }
  } catch (error) {
    console.log(error)
  }
}

export const incrementTestData =
  (testType, correctGuesses) => async (dispatch, getState) => {
    try {
      getFirebase()
      const db = getDatabase()
      const dataRef = ref(db, "data")
      const typeRef = child(dataRef, testType)
      const correctGuessesRef = child(typeRef, correctGuesses)
      // const snapshot = await get(correctGuessesRef)
      // const childExists = snapshot.exists()
      // if (!childExists) {
      //   await set(correctGuessesRef, 1)
      // } else {
      await set(correctGuessesRef, increment(1))
      // }
      // const dataSnapshot = await get(dataRef)
      // if (dataSnapshot.exists()) {
      //   const data = dataSnapshot.val()
      //   dispatch(setGlobalStatsData(data))
      // }
    } catch (error) {
      console.log(error)
    }
  }

export const readStats = () => async (dispatch, getState) => {
  try {
    getFirebase()
    const db = getDatabase()
    const dataRef = ref(db, "data")
    const snap = await get(dataRef)
    if (snap.exists()) {
      const data = snap.val()
      dispatch(setGlobalStatsData(data))
    }
  } catch (error) {
    console.log(error)
  }
}
