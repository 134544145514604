import React, { useState, useEffect, useRef } from "react"
import { EventGA } from "../../../utils/GaEvent"
import seedrandom from "seedrandom"
import { Box } from "../../commonElements"
import PressButton from "../../pressButton"
import { useSelector } from "react-redux"
import { Howl } from "howler"
import FlipSound from "../../../audio/coin-flip-2.mp3"
import CoinController from "./coinController"
import styled from "styled-components"

const GuessPressButton = styled(PressButton)`
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  padding: 11px 40px;
  width: 20rem;
`

const CoinSimu = () => {
  const [flipSound, setFlipSound] = useState(null)
  const [initialStatus, setInitialStatus] = useState("heads")
  const coinQuantityPersist = useSelector(state => state.embed.coin.quantity)
  const {
    backgroundColor: backgroundColorPersist,
    direction: directionPersist,
  } = useSelector(state => state.embed)
  const [coinQuantity, setCoinQuantity] = useState(0)
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF")
  const [direction, setDirection] = useState(0)
  const coinRef = useRef([])

  useEffect(() => {
    const mathRandom = seedrandom()
    setInitialStatus(mathRandom() < 0.5 ? "tails" : "heads")
  }, [])

  useEffect(() => {
    setCoinQuantity(coinQuantityPersist)
  }, [coinQuantityPersist])

  useEffect(() => {
    if (backgroundColorPersist !== backgroundColor) {
      setBackgroundColor(backgroundColorPersist)
    }
  }, [backgroundColorPersist])

  useEffect(() => {
    if (directionPersist !== direction) {
      setDirection(directionPersist)
    }
  }, [directionPersist])

  useEffect(() => {
    setFlipSound(
      new Howl({
        src: [FlipSound],
      })
    )
  }, [])

  const modeName = "None"
  const coinsArray = Array(coinQuantity).fill(0)

  return (
    <Box
      alignItems="center"
      padding="3rem 1rem"
      flex="1"
      width="100%"
      alignSelf="center"
      backgroundColor={backgroundColor}
      style={{ overflowX: direction === 0 ? `unset` : `auto` }}
    >
      <Box width="fit-content">
        <Box
          flexDirection={direction === 0 ? "column" : "row"}
          style={{ minWidth: "fit-content" }}
          maxWidth="100%"
          positionBox="relative"
          mt={coinQuantity === 1 ? "1rem" : "0rem"}
          mb={
            coinQuantity === 1 ? "3rem" : coinQuantity === 2 ? "2rem" : "1rem"
          }
        >
          {coinsArray.map((item, index) => {
            return (
              <CoinController
                key={`coin${index}`}
                coinIndex={index}
                flipSound={flipSound}
                modeName={modeName}
                coinCount={coinsArray.length}
                coinRef={coinRef}
                ref={el => (coinRef.current[index] = el)}
                initialStatus={initialStatus}
              />
            )
          })}
        </Box>
        <GuessPressButton
          alignSelf="center"
          color={backgroundColor === "#282728" ? "#3D3D3D" : "#FFFFFF"}
          backgroundColor={
            backgroundColor === "#282728" ? "#FFFFFF" : "#3D3D3D"
          }
          fontSize="2rem"
          borderWidth="0"
          onClick={timeDiff => {
            if (coinsArray.length === 1) {
              const result = coinRef.current[0].startFlip(timeDiff)
              if (
                typeof window !== "undefined" &&
                process.env.GATSBY_BUILD_CONTEXT === "production"
              ) {
                EventGA({
                  category: `${modeName} Mode`,
                  action: "Coin Button Click",
                  label: result,
                })
              }
            } else {
              for (let i = 0; i < coinRef.current.length; i++) {
                if (coinRef.current[i] !== null) {
                  coinRef.current[i].startFlip(1)
                }
              }
            }
          }}
        >
          FLIP IT
        </GuessPressButton>
      </Box>
    </Box>
  )
}

export default CoinSimu
