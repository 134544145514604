import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react"
import seedrandom from "seedrandom"
import Coin from "./coin"
import { useDispatch, useSelector } from "react-redux"
import { incrementData, incrementMyData } from "../../../redux/stats/actions"
import { setCuidStatusChallenge } from "../../../redux/challenge/actions"
import { EventGA } from "../../../utils/GaEvent"

const CoinController = forwardRef(
  (
    { modeName, flipSound, coinCount, coinIndex, coinRef, initialStatus },
    ref
  ) => {
    const [flip, setFlip] = useState("")
    const [status, setStatus] = useState(initialStatus)
    const [flipNumber, setFlipNumber] = useState(250)
    const [zIndex, setZIndex] = useState(1)
    const soundOn = useSelector(state => state.embed.sound.on)
    const dispatch = useDispatch()

    useEffect(() => {
      setStatus(initialStatus)
    }, [initialStatus])

    useImperativeHandle(ref, () => ({
      startFlip: timeDiff => {
        let random = 250
        let result = ""
        if (timeDiff <= 500) {
          const mathRandom = seedrandom()
          result = mathRandom() < 0.5 ? "tails" : "heads"
          random = Math.round(mathRandom() * 500)
        } else {
          result = timeDiff % 2 === 0 ? "heads" : "tails"
          random = Math.round(timeDiff / 10)
        }
        if (soundOn) {
          let soundVolume = random < 250 ? random / 300.0 : 1
          soundVolume = soundVolume < 0.3 ? 0.3 : soundVolume
          flipSound.volume(soundVolume)
          flipSound.play()
        }
        setZIndex(5)
        setFlip(result)
        setFlipNumber(random)
        return result
      },
    }))

    return (
      <Coin
        flip={flip}
        status={status}
        flipNumber={flipNumber}
        coinCount={coinCount}
        zIndex={zIndex}
        coinIndex={coinIndex}
        onClick={() => {
          const result = coinRef.current[coinIndex].startFlip(1)
          if (
            typeof window !== "undefined" &&
            process.env.GATSBY_BUILD_CONTEXT === "production"
          ) {
            EventGA({
              category: `${modeName} Mode`,
              action: "Coin Click",
              label: result,
            })
          }
        }}
        onAnimationEnd={() => {
          const type = flip
          setStatus(flip)
          setZIndex(1)
          setFlip("")
          if (coinCount === 1 && modeName !== "None") {
            dispatch(setCuidStatusChallenge(flip))
          }

          dispatch(incrementMyData(type))
          dispatch(incrementData(type))
        }}
      />
    )
  }
)

export default CoinController
