import { SET_CHALLENGE, SET_CUID_STATUS_CHALLENGE, SET_GUESS_CHALLENGE } from "./constants"

export const setReduxChallenge = challenge => ({
  type: SET_CHALLENGE,
  payload: {
    challenge,
  },
})

export const setCuidStatusChallenge = status => ({
  type: SET_CUID_STATUS_CHALLENGE,
  payload: {
    status,
  },
})

export const setGuessChallenge = guess => ({
  type: SET_GUESS_CHALLENGE,
  payload: {
    guess,
  },
})