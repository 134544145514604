import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import cuid from "cuid"
import { Box } from "../../commonElements"
import styled, { keyframes, css } from "styled-components"

const flipHeadsToHeads = deg => keyframes`
    0% {
      transform: rotateX(0deg) scale(1);
    }
    25% {
      transform: rotateX(${deg / 3}deg) scale(1.33);
    }
    50% {
      transform: rotateX(${deg / 2}deg) scale(1.5);
    }
    75% {
      transform: rotateX(${(deg * 3) / 4}deg) scale(1.25);
    }
    100% {
      transform: rotateX(${deg}deg) scale(1);
    }
`

const flipHeadsToTails = deg => keyframes`
   0% {
      transform: rotateX(0deg) scale(1);
    }
    25% {
      transform: rotateX(${deg / 3}deg) scale(1.33);
    }
    50% {
      transform: rotateX(${deg / 2}deg) scale(1.5);
    }
    75% {
      transform: rotateX(${(deg * 3) / 4}deg) scale(1.25);
    }
    100% {
      transform: rotateX(${deg}deg) scale(1);
    }
`

const flipTailsToHeads = deg => keyframes`
    0%{
      transform: rotateX(180deg) scale(1);
    }
    25% {
      transform: rotateX(${deg / 3 + 90}deg) scale(1.33);
    }
    50% {
      transform: rotateX(${deg / 2 + 90}deg ) scale(1.5);
    }
    75% {
      transform: rotateX(${(deg * 3) / 4 + 90}deg) scale(1.25);
    }
    100% {
      transform: rotateX(${deg}deg) scale(1);
    }
`

const flipTailsToTails = deg => keyframes`
   0%{
      transform: rotateX(180deg) scale(1);
    }
    25% {
      transform: rotateX(${deg / 3 + 90}deg) scale(1.33);
    }
    50% {
      transform: rotateX(${deg / 2 + 90}deg) scale(1.5);
    }
    75% {
      transform: rotateX(${(deg * 3) / 4 + 90}deg) scale(1.25);
    }
    100% {
      transform: rotateX(${deg}deg) scale(1);
    }
`

const flipAnimation = css`
  animation: ${({ flip, flipDeg }) =>
    css`
      ${flip(flipDeg)} 3.5s
    `};
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-play-state: running;
`
const CoinBox = styled(Box)`
  position: relative;
  min-width: ${props => props.coinCount === 1 ? "23rem" : "19rem"};
  height: ${props => props.coinCount === 1 ? "23rem" : "19rem"};
  margin: .5rem;
  z-index: ${props => props.zIndex};
  transform-style: preserve-3d;
  ${({ flip }) => flip && flipAnimation}
  transform: ${props =>
    props.status === "tails" ? "rotateX(180deg)" : "rotateX(0deg)"};
`

const CoinStyle = css`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-size: contain;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform-style: preserve-3d;
`

const CoinHeads = styled(Box)`
  cursor: pointer;
  background-color: ${props => props.toolColor};
  backface-visibility: hidden;
  ${CoinStyle}
`

const CoinTails = styled(Box)`
  cursor: pointer;
  transform: rotateX(180deg);
  background-color: ${props => props.toolColor};
  backface-visibility: hidden;
  ${CoinStyle}
`

const CoinImage = styled(Box)`
  top: 4%;
  left: 4%;
  width: 92%;
  height: 92%;
  position: absolute;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.colors.borderCoin};
  background-color: transparent;
`

const CoinText = styled.span`
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  width: 100%;
  line-height: 1.2;
  font-size: ${props => props.fontSize};
  text-transform: uppercase;
  color: ${props => props.theme.colors.borderCoin};
  user-select: none;
`
const CoinTextArray = styled.span`
  width: ${props => props.boxSize};
  max-height: ${props => props.boxSize};
  text-align: center;
  display: flex;
  flex-direction: column;
  user-select: none;
  z-index: 1;
`

const CoinHeadsTails = React.memo(({ coinCount, coinIndex }) => {
  const quantity = useSelector(state => state.embed.coin.quantity)
  const headsText = useSelector(state => coinCount <= quantity ? state.embed.coin.texts[coinIndex].heads : "")
  const tailsText = useSelector(state => coinCount <= quantity ? state.embed.coin.texts[coinIndex].tails : "")
  const toolColorsPersist = useSelector(state => state.embed.colors)
  const [toolColors, setToolColors] = useState(["#EEE", "#EEE"])

  useEffect(() => {
    if (toolColorsPersist !== undefined) {
      setToolColors(toolColorsPersist)
    } else {
      setToolColors(["#59569D", "#F25292"])
    }
  }, [toolColorsPersist])

  let mHeadsText = headsText
  let mTailsText = tailsText
  if (headsText === "") {
    mHeadsText = "HEADS"
  }

  if (tailsText === "") {
    mTailsText = "TAILS"
  }
  const headsTextArray = mHeadsText.split(" ")
  const tailsTextArray = mTailsText.split(" ")
  const singleCoin = coinCount === 1
  let headsBoxSize = "88%"
  let headsFontSize = singleCoin ? "4.5rem" : "3.8rem"
  let tailsBoxSize = "88%"
  let tailsFontSize = singleCoin ? "4.5rem" : "3.8rem"

  return (
    <>
      <CoinHeads toolColor={toolColors[0]}>
        <CoinImage>
        </CoinImage>
        <CoinTextArray boxSize={headsBoxSize}>
          {headsTextArray.map((text, index) => {
            return (
              <CoinText
                fontSize={headsFontSize}
                key={`headsText${index}`}
              >
                {text}
              </CoinText>
            )
          })}
        </CoinTextArray>
      </CoinHeads>
      <CoinTails toolColor={toolColors[1]}>
        <CoinImage>
        </CoinImage>
        <CoinTextArray boxSize={tailsBoxSize}>
          {tailsTextArray.map((text, index) => {
            return (
              <CoinText
                fontSize={tailsFontSize}
                key={`tailsText${index}`}
              >
                {text}
              </CoinText>
            )
          })}
        </CoinTextArray>
      </CoinTails>
    </>
  )
})

const Coin = ({
  flip,
  status,
  flipNumber,
  onClick,
  onAnimationEnd,
  coinCount = 1,
  zIndex = 1,
  coinIndex,
}) => {
  const [cuidCoin, setCuidCoin] = useState(cuid())
  const flipNum = (Math.floor(flipNumber / 50) + 2) * 4

  let flipDeg = 0
  let flipAnime = null
  switch (flip) {
    case "heads":
      switch (status) {
        case "heads":
          flipDeg = flipNum * 180
          flipAnime = flipHeadsToHeads
          break
        case "tails":
          flipDeg = flipNum * 180
          flipAnime = flipTailsToHeads
          break
      }
      break
    case "tails":
      switch (status) {
        case "heads":
          flipDeg = (flipNum - 1) * 180
          flipAnime = flipHeadsToTails
          break
        case "tails":
          flipDeg = (flipNum + 1) * 180
          flipAnime = flipTailsToTails
          break
      }
      break
    default:
      flipAnime = null
      break
  }

  useEffect(() => {
    setCuidCoin(cuid())
  }, [coinCount])

  return (
    <CoinBox
      id={`embedCoin${coinIndex + 1}`}
      key={`coin${coinIndex}${cuidCoin}`}
      flip={flipAnime}
      status={status}
      flipDeg={flipDeg}
      onClick={onClick}
      onAnimationEnd={onAnimationEnd}
      coinCount={coinCount}
      zIndex={zIndex}
      coinIndex={coinIndex}
    >
      <CoinHeadsTails coinCount={coinCount} coinIndex={coinIndex} />
    </CoinBox>
  )
}

export default Coin
